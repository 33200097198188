import React, { useEffect, useState } from 'react'
import { Badge, Button, Image, Modal, Table } from 'react-bootstrap'
import { VerificationProcessEnum } from '../enum/VerficationProcess.enum'
import { ClientService } from '../services/client.service'
import moment from 'moment'
import PreviewModal from './Preview.modal'
import toast from 'react-hot-toast'

interface IUserModal {
    show: any,
    handleClose: any
    shiftId: any
}

export default function UserModal(props: IUserModal) {

    const [userData, setUserData] = useState<any>();
    const [showPreviewModal, setShowPreviewModal] = useState<any>(undefined)
    const [verificationEnums, setVerificationEnums] = useState<any>();

    const getUserDataByShiftId = async () => {
        await ClientService.getDatabyUserId(props.shiftId, props.show).then((res) => {
            if (res.status === 200) {
                if (res.status === 200) {
                    setUserData(res.data)
                }
            }
        })
    }


    const getVerificationEnums = async () => {
        await ClientService.getVerificationEnums().then(res => {
            if (res.status === 200) {
                setVerificationEnums(Object.values(res.data.enums).filter((data: any) => data !== "gps").map((data: any) => {
                    return {
                        label: data === 'fingerprint' ? "Right Fingerprint" : data === "fingerprint2" ? "Left Fingerprint" : data,
                        value: data
                    }
                }))
            }
        }).catch(err => {
            toast.error(err.response.data);
        })
    }

    useEffect(() => {
        if (props.show) {
            getUserDataByShiftId();
            getVerificationEnums();
        }
    }, [props.shiftId, props.show])

    return (
        <>
            <Modal
                show={props.show ? true : false}
                onHide={props.handleClose}
                size="lg"
                animation={true}
                backdrop="static"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <h5>
                            User Details
                        </h5>
                        <div className="d-flex align-items-center mb-3">
                            {/* <div style={{ width: 100, height: 100 }}>
                                <Image src={userData?.user?.avatar} width={"100%"} height={"100%"} style={{ borderRadius: "50%" }} onClick={() => setShowPreviewModal(userData?.user?.avatar)} />
                            </div> */}
                            {userData?.user?.avatar ? (
                                <div style={{ width: 100, height: 100 }} onClick={() => setShowPreviewModal(userData?.user?.avatar)}>
                                    <Image src={userData?.user?.avatar} width="100%" height="100%" style={{ borderRadius: '50%' }} />
                                </div>
                            ) : (
                                <span>No Image</span>
                            )}
                        </div>
                        <div className="d-flex align-items-center mb-1">
                            <p className="flex-shrink-0" style={{ fontWeight: "500", width: "200px" }}>Name:</p>
                            <p className="flex-grow-1">{userData?.user?.name || "--"}</p>
                        </div>
                        <div className="d-flex align-items-center mb-1">
                            <p className="flex-shrink-0" style={{ fontWeight: "500", width: "200px" }}>Roll No.:</p>
                            <p className="flex-grow-1">{userData?.user?.rollNo || "--"}</p>
                        </div>
                        <div className="d-flex align-items-center mb-1">
                            <p className="flex-shrink-0" style={{ fontWeight: "500", width: "200px" }}>Gender:</p>
                            <p className="flex-grow-1">{userData?.user?.gender || "--"}</p>
                        </div>
                        <div className="d-flex align-items-center mb-1">
                            <p className="flex-shrink-0" style={{ fontWeight: "500", width: "200px" }}>City:</p>
                            <p className="flex-grow-1">{userData?.user?.city || "--"}</p>
                        </div>
                        <div className="d-flex align-items-center mb-1">
                            <p className="flex-shrink-0" style={{ fontWeight: "500", width: "200px" }}>State:</p>
                            <p className="flex-grow-1">{userData?.user?.state || "--"}</p>
                        </div>
                    </div>
                    <hr />
                    <div>
                        <h5 className="mb-2">
                            Exam Details
                        </h5>
                        <div className="d-flex align-items-center mb-1">
                            <p className="flex-shrink-0" style={{ fontWeight: "500", width: "200px" }}>Exam Name:</p>
                            <p className="flex-grow-1">{userData?.exam?.name}</p>
                        </div>
                        <div className="d-flex align-items-center mb-1">
                            <p className="flex-shrink-0" style={{ fontWeight: "500", width: "200px" }}>Live:</p>
                            <p className="flex-grow-1">{userData?.exam?.isLive ? <span className="fw-bold text-success">Live</span> : <span className="fw-bold text-danger">Not Live</span>}</p>
                        </div>
                    </div>
                    <hr />
                    <div>
                        <h5 className="mb-2">
                            Shift Details
                        </h5>
                        <div className="d-flex align-items-center mb-1">
                            <p className="flex-shrink-0" style={{ fontWeight: "500", width: "200px" }}>Shift Name:</p>
                            <p className="flex-grow-1">{userData?.shift?.shiftName}</p>
                        </div>
                        <div className="d-flex align-items-center mb-1">
                            <p className="flex-shrink-0" style={{ fontWeight: "500", width: "200px" }}>Start Time:</p>
                            <p className="flex-grow-1">{moment(userData?.shift?.startTime).format("DD-MM-YYYY hh:mm a")}</p>
                        </div>
                        <div className="d-flex align-items-center mb-1">
                            <p className="flex-shrink-0" style={{ fontWeight: "500", width: "200px" }}>End Time:</p>
                            <p className="flex-grow-1">{moment(userData?.shift?.endTime).format("DD-MM-YYYY hh:mm a")}</p>
                        </div>
                    </div>
                    <hr />
                    <div className="mt-2">
                        <h5>Verifications</h5>
                        <div>
                            <Table striped bordered hover responsive>
                                <thead>
                                    <tr>
                                        <th style={{ fontSize: 14 }} rowSpan={2}>#</th>
                                        <th style={{ fontSize: 14 }} rowSpan={2}>Center Name</th>
                                        <th style={{ fontSize: 14 }} rowSpan={2}>Biometric Operator</th>
                                        <th style={{ fontSize: 14 }} rowSpan={2}>Live Photo</th>
                                        <th style={{ fontSize: 14 }} rowSpan={2}>Verification Id</th>
                                        <th style={{ fontSize: 14 }} rowSpan={2}>Bypass Type</th>
                                        <th style={{ fontSize: 14 }} rowSpan={2}>Bypass Sub-type</th>
                                        <th style={{ fontSize: 14 }} rowSpan={2}>Bypass Reason</th>
                                        <th style={{ fontSize: 14 }} rowSpan={2}>Bypass Image</th>
                                        <th className='text-center' style={{ fontSize: 14 }} colSpan={verificationEnums?.length}>Verification</th>
                                    </tr>
                                    <tr className="text-muted fs-12">
                                        {/* <th colSpan={9}></th> */}
                                        {verificationEnums?.map((verificationType: any, index: number) => (
                                            <th key={index} className="text-uppercase">{verificationType?.label === "fingerprint" ? "Right Fingerprint" : verificationType?.label === "fingerprint2" ? "Left Fingerprint" : verificationType?.label}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        userData?.verifications?.length === 0 ? (
                                            <tr>
                                                <td colSpan={verificationEnums?.length + 10} className="text-center">No Verifications</td>
                                            </tr>
                                        ) :
                                            userData?.verifications?.map((data: any, index: number) => {
                                                const verificationDetails = data?.verificationDetails || [];
                                                const details = verificationDetails.find((ver: any) => ver.success === true);
                                                return (
                                                    <tr key={index}>
                                                        <td style={{ fontSize: 12 }} className="text-align-center">{index + 1}</td>
                                                        <td style={{ fontSize: 12 }} className="text-align-center">{data?.centerId || 'N/A'}</td>
                                                        <td style={{ fontSize: 12 }} className="text-align-center">{data?.invigilatorId?.userName || 'N/A'}</td>
                                                        <td>
                                                            <div style={{ width: 70, height: 70 }}>
                                                                {data?.userLivePhoto ? (
                                                                    <Image src={data.userLivePhoto} width="100%" height="100%" style={{ borderRadius: '50%' }} onClick={() => setShowPreviewModal(data.userLivePhoto)} />
                                                                ) : (
                                                                    <span>No Photo</span>
                                                                )}
                                                            </div>
                                                        </td>
                                                        <td style={{ fontSize: 12 }} className="text-align-center">{data?.verificationId || 'N/A'}</td>
                                                        <td style={{ fontSize: 12 }} className="text-align-center">{details?.bypassType || 'N/A'}</td>
                                                        <td style={{ fontSize: 12 }} className="text-align-center">{details?.bypassSubType || 'N/A'}</td>
                                                        <td style={{ fontSize: 12 }} className="text-align-center">{details?.bypassReason || 'N/A'}</td>
                                                        <td>
                                                            <div className="d-flex gap-3 align-items-center">
                                                                {details?.bypassImage ? (
                                                                    <div style={{ width: 50, height: 50 }} onClick={() => setShowPreviewModal(details.bypassImage)}>
                                                                        <Image src={details.bypassImage} width="100%" height="100%" style={{ borderRadius: '50%' }} />
                                                                    </div>
                                                                ) : (
                                                                    <span>No Image</span>
                                                                )}
                                                                {userData?.user?.avatar ? (
                                                                    <div style={{ width: 50, height: 50 }} onClick={() => setShowPreviewModal(details.bypassImage2)}>
                                                                        <Image src={details.bypassImage2} width="100%" height="100%" style={{ borderRadius: '50%' }} />
                                                                    </div>
                                                                ) : (
                                                                    <span>No Image</span>
                                                                )}
                                                            </div>
                                                        </td>
                                                        {verificationEnums?.map((verificationType: any, index: number) => {
                                                            const verification = verificationDetails.find(
                                                                (ver: any) => ver.method === verificationType?.label
                                                            );
                                                            return (
                                                                <td style={{ fontSize: 12 }} key={index}>
                                                                    <span className="fs-12 cursor-pointer">
                                                                        {verification ? (
                                                                            verification?.manuallyVerified ? (
                                                                                <Badge bg="warning">Manually Verified</Badge>
                                                                            ) : verification?.success ? (
                                                                                <Badge bg="success">Verified</Badge>
                                                                            ) : (
                                                                                <Badge bg="danger">Not Verified</Badge>
                                                                            )
                                                                        ) : (
                                                                            <Badge bg="secondary">No data</Badge>
                                                                        )}
                                                                    </span>
                                                                </td>
                                                            );
                                                        })}
                                                    </tr>
                                                );
                                            })}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal >

            <PreviewModal
                show={showPreviewModal}
                handleClose={() => setShowPreviewModal(undefined)}
            />
        </>
    )
}

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react'
import { Button, Card, Col, Form, Row, Spinner } from 'react-bootstrap'
import toast from 'react-hot-toast';
import Select from "react-select"
import { ClientService } from '../../component/services/client.service';
import CsrReportTable from '../../component/table/CsrReportTable';
import { faRefresh } from '@fortawesome/free-solid-svg-icons';
import TablePaginationComponent from '../../component/table/TablePagination';
import InvigilatorTable from '../../component/table/Invigilator.table';
import { saveAsCSV } from '../../component/helpers/csvhelper';
import moment from 'moment';

const Invigilators = () => {


    const [selectValue, setSelectValue] = useState<any>();
    const [shifts, setShifts] = useState<any>();
    const [data, setData] = useState<any[]>([]);

    const [loading, setLoading] = useState<boolean>(false);
    const [csvLoading, setCSVLoading] = useState<boolean>(false);

    const [shiftDate, setShiftDate] = useState<any>();
    const [selectedShiftDate, setSelectedShiftDate] = useState<any>()

    const [centers, setCenters] = useState<any>();
    const [selectedCenters, setSelectedCenters] = useState<any>();

    const [zones, setZones] = useState<any>();
    const [districts, setDistricts] = useState<any>();
    const [states, setStates] = useState<any>();


    const [selectedZones, setSelectedZones] = useState<any>();
    const [selectedDistricts, setSelectedDistricts] = useState<any>();
    const [selectedStates, setSelectedStates] = useState<any>();

    const [pageNumber, setPageNumber] = useState<number>(1);
    const [pageSize, setPageSize] = useState<any>(10);
    const [totalCount, setTotalCount] = useState<number>(0);


    // const getAllZones = async () => {
    //     await ClientService.getUniqueZone(selectValue).then(res => {
    //         if (res.status === 200) {
    //             setZones(res.data.map((zone: any) => {
    //                 return {
    //                     value: zone,
    //                     label: zone
    //                 }
    //             }))
    //         }
    //     }).catch(err => {
    //         toast.error(err.response.data);
    //     })
    // }


    const getUniqueCenter = async () => {
        await ClientService.getUniqueCenter(selectValue).then(res => {
            if (res.status === 200) {
                setCenters(res.data.map((zone: any) => {
                    return {
                        value: zone,
                        label: zone
                    }
                }))
            }
        }).catch(err => {
            toast.error(err.response.data);
        })
    }

    // const getUniqueState = async () => {
    //     await ClientService.getUniqueState(selectValue).then(res => {
    //         if (res.status === 200) {
    //             setStates(res.data.map((zone: any) => {
    //                 return {
    //                     value: zone,
    //                     label: zone
    //                 }
    //             }))
    //         }
    //     }).catch(err => {
    //         toast.error(err.response.data);
    //     })
    // }


    // const getUniqueCity = async () => {
    //     await ClientService.getUniqueCity(selectValue).then(res => {
    //         if (res.status === 200) {
    //             setDistricts(res.data.map((zone: any) => {
    //                 return {
    //                     value: zone,
    //                     label: zone
    //                 }
    //             }))
    //         }
    //     }).catch(err => {
    //         toast.error(err.response.data);
    //     })
    // }



    // const [pageNumber, setPageNumber] = useState<number>(1);
    // const [pageSize, setPageSize] = useState<any>(10);
    // const [totalCount, setTotalCount] = useState<number>(0);

    const getAllShift = async () => {
        await ClientService.getAllShifts().then(res => {
            if (res.status === 200) {
                setShifts(res.data.shifts.map((data: any) => {
                    return {
                        label: data.shiftName + " - " + moment(data.startTime).format("DD MMM YYYY"),
                        value: data._id
                    }
                }))
            }
        }).catch(err => {
            toast.error(err.response.data);
        })
    }

    const getData = async () => {
        const payload = {
            centerId: selectedCenters?.map((data: any) => data?.value),
        }
        setLoading(true)
        await ClientService.getInvigilators(selectValue, pageNumber, pageSize, payload)
            .then((res) => {
                if (res.status === 200) {
                    setData(res?.data?.invigilators || []);
                }
            })
            .catch((err) => {
                console.log(err);
                toast.error(err?.response?.data);
            })
            .finally(() => setLoading(false))
    };

    async function downloadCsv() {
        const payload = {
            centerId: selectedCenters?.map((data: any) => data?.value),
        }
        setCSVLoading(true);
        await ClientService.getInvigilators(selectValue, pageNumber, pageSize, payload)
            .then((res) => {
                if (res.status === 200) {
                    const csvData = [
                        'Center,User Name,Email,Last Sync Time,Last Download Time,Download %,Upload %,Last Login Time,Last Logout Time',
                        ...res.data.invigilators.map((item: any) =>
                            `${item?.centerId?.[0]},${item?.userName},${item?.emailId},${moment(item?.lastSyncedOn).format("DD-MM-YYYY hh:mm A")},${moment(item?.lastDownloadAt).format("DD-MM-YYYY hh:mm A")},${item?.meta.downloadStats.percentage},${item?.meta.uploadStats.percentage},${moment(item?.meta?.loginTimes?.[item?.meta?.loginTimes?.length - 1]).format("DD-MM-YYYY hh:mm A")},${moment(item?.meta?.logoutTimes?.[item?.meta?.logoutTimes?.length - 1]).format("DD-MM-YYYY hh:mm A")}`
                        )
                    ].join('\n');
                    saveAsCSV(csvData, 'invigilators-data');
                }
            })
            .catch((err) => {
                console.log(err);
                toast.error(err?.response?.data);
            })
            .finally(() => setCSVLoading(false))
    }

    useEffect(() => {
        if (selectValue) {
            // getAllZones();
            getUniqueCenter();
            // getUniqueCity();
            // getUniqueState();
        }
    }, [selectValue])


    useEffect(() => {
        if (selectValue) {
            getData();
        }
    }, [selectValue, selectedCenters]);

    useEffect(() => {
        getAllShift();
    }, [])

    return (
        <>
            <Row className="mt-5 text-left">
                <Col md={8}>
                    <h4 className="d-flex text-secondary">Biometric Operators</h4>
                </Col>
                <Col md={4}>
                    <Form.Label className="text-muted fw-bold">
                        Select Shift
                    </Form.Label>
                    <Select
                        options={shifts}
                        placeholder="Select Shifts"
                        onChange={(e: any) => setSelectValue(e?.value)}
                        isClearable
                        value={selectValue ? shifts.find((shift: any) => shift.value === selectValue.value) : null}
                    />
                </Col>
            </Row>

            <Card
                className="mt-3"
                style={{
                    backgroundColor: "#f9f9f9",
                    borderRadius: "12px",
                    border: "none",
                }}
            >
                <Card.Body>
                    <Row>
                        {/* <Col md={3}>
                            <Form.Group>
                                <Form.Label className="text-muted fw-bold">
                                    Zones
                                </Form.Label>
                                <Select
                                    options={[{ label: "All", value: "All" }, ...(zones?.map((state: any) => ({ label: state?.label, value: state?.value })) || [])]}
                                    onChange={(selectedOptions: any) => {
                                        if (selectedOptions?.some((option: any) => option.value === "All")) {
                                            setSelectedZones(
                                                zones?.map((state: any) => ({
                                                    label: state?.label,
                                                    value: state?.value,
                                                })) || []
                                            );
                                        } else {
                                            setSelectedZones(selectedOptions || []);
                                        }
                                    }}
                                    value={selectedZones}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    isClearable
                                    isMulti
                                />
                            </Form.Group>
                        </Col> */}
                        {/* <Col md={3}>
                            <Form.Group>
                                <Form.Label className="text-muted fw-bold">
                                    States
                                </Form.Label>
                                <Select
                                    options={[{ label: "All", value: "All" }, ...(states?.map((state: any) => ({ label: state?.label, value: state?.value })) || [])]}
                                    onChange={(selectedOptions: any) => {
                                        if (selectedOptions?.some((option: any) => option.value === "All")) {
                                            setSelectedStates(
                                                states?.map((state: any) => ({
                                                    label: state?.label,
                                                    value: state?.value,
                                                })) || []
                                            );
                                        } else {
                                            setSelectedStates(selectedOptions || []);
                                        }
                                    }}
                                    value={selectedStates}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    isClearable
                                    isMulti
                                />

                            </Form.Group>
                        </Col> */}
                        {/* <Col md={3}>
                            <Form.Group>
                                <Form.Label className="text-muted fw-bold">
                                    City
                                </Form.Label>
                                <Select
                                    options={[{ label: "All", value: "All" }, ...(districts?.map((state: any) => ({ label: state?.label, value: state?.value })) || [])]}
                                    onChange={(selectedOptions: any) => {
                                        if (selectedOptions?.some((option: any) => option.value === "All")) {
                                            setSelectedDistricts(
                                                districts?.map((state: any) => ({
                                                    label: state?.label,
                                                    value: state?.value,
                                                })) || []
                                            );
                                        } else {
                                            setSelectedDistricts(selectedOptions || []);
                                        }
                                    }}
                                    value={selectedDistricts}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    isClearable
                                    isMulti
                                />
                            </Form.Group>
                        </Col> */}
                        <Col md={3}>
                            <Form.Group>
                                <Form.Label className="text-muted fw-bold">
                                    Centers
                                </Form.Label>
                                <Select
                                    options={[{ label: "All", value: "All" }, ...(centers?.map((state: any) => ({ label: state?.label, value: state?.value })) || [])]}
                                    onChange={(selectedOptions: any) => {
                                        if (selectedOptions?.some((option: any) => option.value === "All")) {
                                            setSelectedCenters(
                                                centers?.map((state: any) => ({
                                                    label: state?.label,
                                                    value: state?.value,
                                                })) || []
                                            );
                                        } else {
                                            setSelectedCenters(selectedOptions || []);
                                        }
                                    }}
                                    value={selectedCenters}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    isClearable
                                    isMulti
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>

            <Row>
                <Col>
                    <InvigilatorTable data={data} loading={loading} />
                </Col>
            </Row>
            <Row className="mt-3">
                <Col>
                    <TablePaginationComponent
                        currentPage={pageNumber}
                        itemsCount={totalCount}
                        itemsPerPage={pageSize}
                        setItemsPerPage={(e: any) => setPageSize(e)}
                        setCurrentPage={setPageNumber}
                    />
                </Col>
            </Row>
        </>
    )
}

export default Invigilators;
import { faUser, faCheckCircle, faRefresh } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Row, Col, Button, Card, Spinner } from 'react-bootstrap'
import { UsersCard } from '../../component/Card/Statistics.card'
import Select from 'react-select'
import { ClientService } from '../../component/services/client.service'
import toast from 'react-hot-toast'
import VerifiedStats from './VerifiedStats'
import moment from 'moment'
import CetLoader from '../../component/Loader/CetLoader'


interface ICSRStats {
    shift: any
}

export default function CsrStatsIndex(props: ICSRStats) {
    const [countData, setCountData] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);
    const getCountData = async () => {
        setLoading(true);
        await ClientService.getCountInfo(props.shift)
            .then((res) => {
                if (res.status === 200) {
                    setCountData(res.data);
                }
            })
            .catch((err) => {
                console.log(err);
                toast.error(err.response.data);
            })
            .finally(() => setLoading(false));
    };

    console.log()

    useEffect(() => {
        if (props.shift) {
            getCountData();
        }
    }, [props.shift]);

    return (
        <>
            <Row className="mt-3">
                <Col>
                    <Card
                        style={{
                            backgroundColor: "#dcdcdc",
                            borderRadius: "12px",
                            border: "none",
                        }}
                    // className='p-1'
                    >
                        <div className="d-flex align-items-center  justify-content-end ">
                            <FontAwesomeIcon icon={faRefresh} className='m-2 cursor-pointer' onClick={getCountData} />
                        </div>
                        <Card
                            style={{
                                backgroundColor: "#f9f9f9",
                                borderRadius: "12px",
                                border: "none",
                            }}
                        >
                            <Card.Body>
                                {
                                    loading ? <CetLoader /> :
                                        <>
                                            <div className="d-flex justify-content-between align-items-center mb-3">
                                                <div>
                                                    <h6>Statistics</h6>
                                                </div>
                                            </div>
                                            <div className='d-flex flex-wrap gap-2 mt-3'>
                                                <div>
                                                    <UsersCard
                                                        reactIcon={
                                                            <FontAwesomeIcon icon={faCheckCircle} className="fs-1 text-muted" />
                                                        }
                                                        header={"Registered Candidate Count"}
                                                        statsValue={countData && countData["Biometric Count"] || 0}
                                                    />
                                                </div>
                                                <div>
                                                    <UsersCard
                                                        reactIcon={
                                                            <FontAwesomeIcon icon={faCheckCircle} className="fs-1 text-muted" />
                                                        }
                                                        header={"Biometric Operator Count"}
                                                        statsValue={countData && countData["Invigilator Count"] || 0}
                                                    />
                                                </div>
                                                <div>
                                                    <UsersCard
                                                        reactIcon={
                                                            <FontAwesomeIcon icon={faCheckCircle} className="fs-1 text-muted" />
                                                        }
                                                        header={"Candidate Count"}
                                                        statsValue={countData && countData["Users Count"] || 0}
                                                    />
                                                </div>

                                                <div>
                                                    <UsersCard
                                                        reactIcon={
                                                            <FontAwesomeIcon icon={faCheckCircle} className="fs-1 text-muted" />
                                                        }
                                                        header={"Bypassed Candidate Count"}
                                                        statsValue={countData && countData["By-Passed Total"] || 0}
                                                    />
                                                </div>
                                                {countData?.verification_data?.filter((data: any) => data.method !== "gps")?.map((data: any, index: number) => (
                                                    <UsersCard
                                                        key={index}
                                                        reactIcon={
                                                            <FontAwesomeIcon icon={faCheckCircle} className="fs-1 text-muted" />
                                                        }
                                                        header={
                                                            data?.method === "fingerprint"
                                                                ? "Right Fingerprint"
                                                                : data?.method === "fingerprint2"
                                                                    ? "Left Fingerprint"
                                                                    : data?.method
                                                        }
                                                        statsValue={data?.total || 0}
                                                    />
                                                ))}

                                            </div>
                                        </>
                                }
                            </Card.Body>
                        </Card>
                    </Card>
                </Col>
            </Row>
        </>
    )
}
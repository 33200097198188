import React, { useEffect, useState } from 'react'
import { ClientService } from '../../component/services/client.service';
import { Button, Card, Col, Row, Spinner, Table } from 'react-bootstrap';
import { UsersCard } from '../../component/Card/Statistics.card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faClock, faRefresh } from '@fortawesome/free-solid-svg-icons';
import CetLoader from '../../component/Loader/CetLoader';

interface IAvarageTimingStats {
    shift: any,
    selectedCenters: any;
    selectedZones: any;
    selectedDistrict: any;
    selectedStates: any;
}

export default function AvarageTimingStats(props: IAvarageTimingStats) {

    const [averageTiming, setAverageTiming] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);

    const getAverageTiming = async () => {
        setLoading(true);
        await ClientService.getCityStatsTime(props.shift).then((res) => {
            if (res.status === 200) {
                setAverageTiming(res.data.stats)
            }
        }).finally(() => setLoading(false));
    }

    useEffect(() => {
        if (props.shift) {
            getAverageTiming();
        }
    }, [props.shift, props.selectedCenters, props.selectedZones, props.selectedDistrict, props.selectedStates]);
    return (
        <>
            <Row className="mt-3">
                <Col>
                    <Card
                        style={{
                            backgroundColor: "#dcdcdc",
                            borderRadius: "12px",
                            border: "none",
                        }}
                        className=''
                    >
                        <Card.Body>
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <div>
                                    <h6>City Wise Average Handling</h6>
                                </div>
                            </div>
                            <div className='d-flex flex-wrap gap-2 mt-3'>
                            {loading ? <CetLoader /> :
                                <Table striped bordered hover bgcolor='white'>
                                    <thead>
                                       <tr>
                                            <th>
                                                #
                                            </th>
                                            <th>
                                                City
                                            </th>
                                            <th>
                                                Time
                                            </th>
                                        </tr> 
                                    </thead>
                                    <tbody>
                                {averageTiming?.map((data: any, index: number) => {
                                    return (
                                        <tr>
                                            <td>
                                                {index + 1}
                                            </td>
                                            <td>
                                                {data.city}
                                            </td>
                                            <td>
                                                {((data.avgTimeInMin)*60).toFixed(0)} sec(s)
                                            </td>
                                        </tr>

                                    )
                                })}
                                </tbody>
                                </Table>
}
                                </div>
                                </Card.Body>
                    </Card>

                </Col>
            </Row>
        </>
    )
}

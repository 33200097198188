import React, { useEffect, useState } from 'react'
import VerifiedStats from '../Csr/VerifiedStats'
import { ClientService } from '../../component/services/client.service'
import toast from 'react-hot-toast'
import Select from "react-select"
import moment from 'moment'
import { Button, Card, Col, Form, Row } from 'react-bootstrap'
import CsrStatsIndex from '../Csr/CsrStats.index'
import AvarageTimingStats from './AvarageTimingStats'
export default function Dashboard() {

    const [selectedValue, setSelectedValue] = useState<any>()
    const [shifts, setShifts] = useState<any>()
    // const [shiftDate, setShiftDate] = useState<any>();
    // const [selectedShiftDate, setSelectedShiftDate] = useState<any>()

    const [centers, setCenters] = useState<any>();
    const [allCenters, setAllCenters] = useState<any>();
    const [selectedCenters, setSelectedCenters] = useState<any>();
    const [isFilter, setIsFilter] = useState<boolean>(true);

    const [zones, setZones] = useState<any>();
    const [districts, setDistricts] = useState<any>();
    const [states, setStates] = useState<any>();


    const [selectedZones, setSelectedZones] = useState<any>();
    const [selectedDistricts, setSelectedDistricts] = useState<any>();
    const [selectedStates, setSelectedStates] = useState<any>();


    const getAllShift = async () => {
        await ClientService.getAllShifts().then(res => {
            if (res.status === 200) {
                setShifts(res.data.shifts.map((data: any) => {
                    return {
                        label: data.shiftName + " - " + moment(data.startTime).format("DD MMM YYYY"),
                        value: data._id
                    }
                }))
                // setShiftDate(res.data.shifts.map((data: any) => {
                //     return {
                //         label: moment(data.startTime).format("DD MMM YYYY"),
                //         value: data._id
                //     }
                // }))
            }
        }).catch(err => {
            toast.error(err.response.data);
        })
    }

    const getAllZones = async () => {
        await ClientService.getUniqueZone(selectedValue).then(res => {
            if (res.status === 200) {
                setZones(res.data.map((zone: any) => {
                    return {
                        value: zone,
                        label: zone
                    }
                }))
            }
        }).catch(err => {
            toast.error(err.response.data);
        })
    }


    const getUniqueCenter = async () => {
        let params = new URLSearchParams();
        selectedZones.forEach((zone: any) => params.append("zone[]", zone?.value));
        selectedStates.forEach((state: any) => params.append("state[]", state?.value));
        selectedDistricts.forEach((district: any) => params.append("district[]", district?.value));

        await ClientService.getCenters(selectedValue, "?" + params.toString()).then(res => {
            if (res.status === 200) {
                setCenters(res.data?.centers?.map((zone: any) => {
                    return {
                        value: zone,
                        label: zone
                    }
                }))
            }
        }).catch(err => {
            toast.error(err.response.data);
        })
    }
    const getUniqueState = async () => {
        const params = new URLSearchParams();
        selectedZones.forEach((zone: any) => params.append("zone[]", zone?.value));
        await ClientService.getStates(selectedValue, "?" + params.toString()).then(res => {
            if (res.status === 200) {
                setStates(res.data.states?.map((zone: any) => {
                    return {
                        value: zone,
                        label: zone
                    }
                }))
            }
        }).catch(err => {
            toast.error(err.response.data);
        })
    }


    const getUniqueCity = async () => {
        let params = new URLSearchParams();
        selectedZones.forEach((zone: any) => params.append("zone[]", zone?.value));
        selectedStates.forEach((state: any) => params.append("state[]", state?.value));
        await ClientService.getCities(selectedValue, "?" + params.toString()).then(res => {
            if (res.status === 200) {
                setDistricts(res.data?.districts?.map((zone: any) => {
                    return {
                        value: zone,
                        label: zone
                    }
                }))
            }
        }).catch(err => {
            toast.error(err.response.data);
        })
    }

    const getAllCenters = async () => {
        await ClientService.getCenters(selectedValue, "").then(res => {
            if (res.status === 200) {
                setAllCenters(res.data?.centers?.map((zone: any) => {
                    return {
                        value: zone,
                        label: zone
                    }
                }))
            }
        }).catch(err => {
            toast.error(err.response.data);
        })
    }

    useEffect(() => {
        if (selectedValue) {
            getAllZones();
            getAllCenters();
        }
    }, [selectedValue]);

    useEffect(() => {
        if (selectedZones) {
            getUniqueState();
        }
    }, [selectedZones]);

    useEffect(() => {
        if (selectedStates && selectedZones) {
            getUniqueCity();
        }
    }, [selectedStates]);

    useEffect(() => {
        if (selectedStates && selectedZones && selectedDistricts) {
            getUniqueCenter();
        }
    }, [selectedDistricts]);

    useEffect(() => {
        getAllShift();
    }, [])


    return (
        <>
            <Row className="mt-5 text-left">
                <Col md={8}>
                    <h4 className="text-secondary mt-3 fw-bold">Dashboard</h4>
                </Col>
                <Col md={4}>
                    <Form.Label className="text-muted fw-bold">
                        Select Shift
                    </Form.Label>
                    <Select
                        options={shifts}
                        // onChange={(e: any) => setSelectedShiftDate(e)} // Store full object
                        placeholder="Select Shifts"
                        onChange={(e: any) => setSelectedValue(e?.value)}
                        isClearable
                        value={selectedValue ? shifts.find((shift: any) => shift.value === selectedValue.value) : null}
                    />
                </Col>
                {/* <Select
                        options={
                            selectedShiftDate
                                ? shifts?.filter((shift: any) => shift.value === selectedShiftDate.value)
                                : []
                        }
                        onChange={(e: any) => setSelectedValue(e?.value)}
                        placeholder="Select shift"
                        isClearable
                    /> */}

            </Row>
            <Card
                className="mt-3"
                style={{
                    backgroundColor: "#f9f9f9",
                    borderRadius: "12px",
                    border: "none",
                }}
            >
                <Card.Body>
                    <Form.Switch 
                        id="custom-switch"
                        label="Show Filter"
                        checked={isFilter}
                        onChange={(e: any) => { setIsFilter(e.target.checked); setSelectedCenters([]) }}
                    />
                    {
                        isFilter ?
                            <Row>
                                <Col md={3}>
                                    <Form.Group>
                                        <Form.Label className="text-muted fw-bold">
                                            Divisions
                                        </Form.Label>
                                        <Select
                                            options={[{ label: "All", value: "All" }, ...(zones?.map((state: any) => ({ label: state?.label, value: state?.value })) || [])]}
                                            onChange={(selectedOptions: any) => {
                                                if (selectedOptions?.some((option: any) => option.value === "All")) {
                                                    setSelectedZones(
                                                        zones?.map((state: any) => ({
                                                            label: state?.label,
                                                            value: state?.value,
                                                        })) || []
                                                    );
                                                } else {
                                                    setSelectedZones(selectedOptions || []);
                                                }
                                            }}
                                            value={selectedZones}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            isClearable
                                            isMulti
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={3}>
                                    <Form.Group>
                                        <Form.Label className="text-muted fw-bold">
                                            States
                                        </Form.Label>
                                        <Select
                                            options={[{ label: "All", value: "All" }, ...(states?.map((state: any) => ({ label: state?.label, value: state?.value })) || [])]}
                                            onChange={(selectedOptions: any) => {
                                                if (selectedOptions?.some((option: any) => option.value === "All")) {
                                                    setSelectedStates(
                                                        states?.map((state: any) => ({
                                                            label: state?.label,
                                                            value: state?.value,
                                                        })) || []
                                                    );
                                                } else {
                                                    setSelectedStates(selectedOptions || []);
                                                }
                                            }}
                                            value={selectedStates}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            isClearable
                                            isMulti
                                        />

                                    </Form.Group>
                                </Col>
                                <Col md={3}>
                                    <Form.Group>
                                        <Form.Label className="text-muted fw-bold">
                                            City
                                        </Form.Label>
                                        <Select
                                            options={[{ label: "All", value: "All" }, ...(districts?.map((state: any) => ({ label: state?.label, value: state?.value })) || [])]}
                                            onChange={(selectedOptions: any) => {
                                                if (selectedOptions?.some((option: any) => option.value === "All")) {
                                                    setSelectedDistricts(
                                                        districts?.map((state: any) => ({
                                                            label: state?.label,
                                                            value: state?.value,
                                                        })) || []
                                                    );
                                                } else {
                                                    setSelectedDistricts(selectedOptions || []);
                                                }
                                            }}
                                            value={selectedDistricts}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            isClearable
                                            isMulti
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={3}>
                                    <Form.Group>
                                        <Form.Label className="text-muted fw-bold">
                                            Centers
                                        </Form.Label>
                                        <Select
                                            options={[{ label: "All", value: "All" }, ...(centers?.map((state: any) => ({ label: state?.label, value: state?.value })) || [])]}
                                            onChange={(selectedOptions: any) => {
                                                if (selectedOptions?.some((option: any) => option.value === "All")) {
                                                    setSelectedCenters(
                                                        centers?.map((state: any) => ({
                                                            label: state?.label,
                                                            value: state?.value,
                                                        })) || []
                                                    );
                                                } else {
                                                    setSelectedCenters(selectedOptions || []);
                                                }
                                            }}
                                            value={selectedCenters}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            isClearable
                                            isMulti
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            :
                            <Row>
                                <Col md={6}>
                                    <Form.Group>
                                        <Form.Label className="text-muted fw-bold">
                                            Search Center
                                        </Form.Label>
                                        <Select
                                            options={allCenters}
                                            onChange={(selectedOptions: any) => setSelectedCenters(selectedOptions)}
                                            value={selectedCenters}
                                            isClearable
                                            isMulti
                                            isSearchable
                                            placeholder="Type to search Center"
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                    }
                </Card.Body>
            </Card>
            <div className="mt-3">
                <VerifiedStats
                    shiftId={selectedValue}
                    selectedCenters={selectedCenters}
                    selectedZones={selectedZones}
                    selectedDistrict={selectedDistricts}
                    selectedStates={selectedStates}
                    states={states}
                    cities={districts}
                />
            </div>

            <div className="mt-3 mb-3">
                <AvarageTimingStats
                    shift={selectedValue}
                    selectedCenters={selectedCenters}
                    selectedZones={selectedZones}
                    selectedDistrict={selectedDistricts}
                    selectedStates={selectedStates} />
            </div>


            <div className="mt-3 mb-3">
                <CsrStatsIndex shift={selectedValue} />
            </div>


        </>
    )
}

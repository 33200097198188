import React, { useEffect, useState } from "react";
import { ClientService } from "../../component/services/client.service";
import { Card, Col, Row, Spinner } from "react-bootstrap";
import { AgCharts } from "ag-charts-react";
import Select from "react-select"
import { Label } from "recharts";
import moment from "moment";
import StateStats from "../Dashboard/StateStats";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import CetLoader from "../../component/Loader/CetLoader";
interface IVerifiedStats {
  shiftId: any;
  selectedCenters: any;
  selectedZones: any;
  selectedDistrict: any;
  selectedStates: any;
  states: any,
  cities: any
}

export default function VerifiedStats(props: IVerifiedStats) {
  const [chartOptions, setChartOptions] = useState<any>(null);
  const [overallChartOptions, setOverallChartOptions] = useState<any>(null);
  const [lineChartOptions, setLineChartOptions] = useState<any>(null);
  const [userCountBarChart, setUserCountBarChart] = useState<any>(null);
  const [userCountPieChart, setUserCountPieChart] = useState<any>(null);
  const [verifiedPieChart, setVerifiedPieChart] = useState<any>(null);
  const [byPassPieChart, setByPassPieChart] = useState<any>(null);
  const [selectedTime, setSelectedTime] = useState<any>();

  const [barChartOptions, setBarChartsOptions] = useState<any>();
  const [barChartCountOptions, setBarChartsCountOptions] = useState<any>();

  const [selectedStateData, setSelectedStateData] = useState<any>();

  const [loading, setLoading] = useState<boolean>(false)
  const [stateLoading, setStateLoading] = useState<boolean>(false)

  const getVerificationCount = async () => {
    try {
      const payload = {
        state: props.selectedStates?.map((data: any) => data?.value),
        city: props.selectedDistrict?.map((data: any) => data?.value),
        zone: props.selectedZones?.map((data: any) => data?.value),
        centerId: props.selectedCenters?.map((data: any) => data?.value),
      }
      setLoading(true)
      const res = await ClientService.getVerifiedCount(props.shiftId, payload);
      if (res.status === 200) {
        const data = {
          Pending: res.data?.notVerified,
          Verified: res.data?.verified,

        };

        const overall_data = {
          Pending: res.data?.notVerified,
          Verified: res.data?.verified,
          Bypass: res.data?.bypassedUsers,
          Additional_Candidate: res.data?.additionalUsers
        };

        const pieChartData = Object.entries(data).map(([key, value]) => ({
          asset: key,
          amount: value,
          fill: key === "Verified" && "green",
        }));

        const pieChartOverallData = Object.entries(overall_data).map(([key, value]) => ({
          asset: key,
          amount: value,
          fill: key === "Verified" && "green",
        }));

        setChartOptions({
          data: pieChartData,
          title: {
            text: "",
          },
          series: [
            {
              type: "pie",
              calloutLabelKey: "asset",
              angleKey: "amount",
              innerRadiusRatio: 0.7,
              fills: pieChartData?.map((item) => item.fill)
            },
          ],
        });
        setOverallChartOptions({
          data: pieChartOverallData,
          title: {
            text: "",
          },
          series: [
            {
              type: "pie",
              calloutLabelKey: "asset",
              angleKey: "amount",
              innerRadiusRatio: 0.7,
              fills: pieChartOverallData?.map((item) => item.fill)
            },
          ],
        });
      }
    } catch (error) {
      console.error("Error fetching verification count:", error);
    }
    setLoading(false)
  };

  const getVerificationCountStats = async () => {
    try {
      const payload = {
        state: props.selectedStates?.map((data: any) => data?.value),
        city: props.selectedDistrict?.map((data: any) => data?.value),
        zone: props.selectedZones?.map((data: any) => data?.value),
        centerId: props.selectedCenters?.map((data: any) => data?.value),
        intervalMinutes: selectedTime?.value
      }
      setLoading(true)
      const res = await ClientService.getVerificationCountStats(props.shiftId, payload);

      const chart_data = res.data?.map((data: any, index: number) => {
        return {
          verification: Number(data?.percentage),
          time: moment(data?.start).format("DD-MM-YY, hh:mm A")
        }
      })

      setLineChartOptions({
        title: {
          text: "",
        },
        data: chart_data,
        series: [
          {
            type: "line",
            xKey: "time",
            yKey: "verification",
            yName: "Verification Percentage (%)",
            strokeWidth: 4,
            marker: {
              enabled: true,
            },
            interpolation: { type: "smooth" },
            tooltip: {
              renderer: (params: any) => {
                return {
                  content: `${params.xValue}: ${params.yValue}%`,
                };
              },
            },
            label: {
              formatter: (params: any) => `${params.value}%`,
            },
          },
        ],
      });
    } catch (error) {
      console.error("Failed to fetch verification count stats:", error);
    }
    setLoading(false)
  };

  const getVerificationByState = async () => {
    try {
      const payload = {
        states: selectedStateData?.map((data: any) => data?.value) || []
      }
      setStateLoading(true)
      const res = await ClientService.getVerificationByState(props.shiftId, payload);

      setBarChartsOptions({
        title: {
          text: "",
        },
        data: res.data,
        series: [
          {
            type: "bar",
            xKey: "state",
            yKey: "verificationPercentage",
            yName: "Verification Percentage",
            cornerRadius: 10,
            tooltip: {
              renderer: (params: any) => {
                return {
                  content: `${params.xValue}: ${params.yValue}%`,
                };
              },
            },
            label: {
              formatter: (params: any) => `${params.value}%`,
            },
          },
        ],

      });

      setBarChartsCountOptions({
        title: {
          text: "",
        },
        data: res.data,
        series: [
          {
            type: "bar",
            xKey: "state",
            yKey: "totalUsers",
            yName: "Total Users",
            stacked: true,
            cornerRadius: 10,
          },
          {
            type: "bar",
            xKey: "state",
            yKey: "verifiedCount",
            yName: "Verified Count",
            stacked: true,
            cornerRadius: 10,
          },
        ],

      });
    } catch (error) {
      console.error("Failed to fetch verification count stats:", error);
    }
    setStateLoading(false)
  };


  const time_interval = [
    {
      label: "10 min",
      value: 10
    },
    {
      label: "20 min",
      value: 20
    },
    {
      label: "30 min",
      value: 30
    },
    {
      label: "40 min",
      value: 40
    },
    {
      label: "50 min",
      value: 50
    },
    {
      label: "1 Hour",
      value: 60
    },
    {
      label: "90 min",
      value: 90
    },
    {
      label: "2 Hour",
      value: 120
    },
  ]

  async function getVerifiedUserCount() {
    setLoading(true)
    await ClientService.getVerifiedUserCount(props.shiftId)
      .then((res) => {
        if (res.status === 200) {

          let chartData = [
            { type: "Verified", count: res.data?.verified || 0 },
            { type: "Not Verified", count: res.data?.notVerified },
            { type: "Manual", count: res.data?.manual },
            { type: "Total Users", count: res.data?.totalUsers },
            { type: "Additional Users", count: res.data?.additionalUsers },
          ]

          let pieChartOverallData = [
            {
              label: "Registered",
              value: res.data?.verified || 0,
              fill: "#008000"
            },
            {
              label: "Not Registered",
              value: res.data?.notVerified,
              fill: "#FF0000"
            }
          ];

          let pieChartVerfied = [
            {
              label: "Verified",
              value: (res.data?.totalUsers - res.data?.manual) || 0,
              fill: "#008000"
            },
            {
              label: "Exceptions",
              value: res.data?.manual || 0,
              fill: "#FF0000"
            }
          ];

          let gpsCount = res.data?.byPassInfo?.method_wise.filter((item: any) => item.method === "gps");
          let bypassPieChartData = res.data?.byPassInfo?.method_wise.filter((item: any) => item.method !== "gps").map((item: any) => {
            return {
              label: item.method === "fingerprint" ? "Left Fingerprint" : item.method === "fingerprint2" ? "Right Fingerprint" : item.method?.toUpperCase(),
              value: parseFloat(((item.count / (res.data?.byPassInfo?.totalVerificationsByPassed - gpsCount[0]?.count)) * 100).toFixed(2)) || 0,
            }
          })

          setUserCountBarChart({
            title: {
              text: ""
            },
            data: chartData,
            series: [
              {
                type: "bar",
                xKey: "type",
                yKey: "count",
                yName: "Count",
              }
            ]
          });

          setUserCountPieChart({
            title: {
              text: ""
            },
            data: pieChartOverallData,
            series: [
              {
                type: "pie",
                angleKey: "value",
                labelKey: "label",
                calloutLabelKey: "label",
              }
            ]
          })
          setVerifiedPieChart({
            title: {
              text: ""
            },
            data: pieChartVerfied,
            series: [
              {
                type: "pie",
                angleKey: "value",
                labelKey: "label",
                calloutLabelKey: "label",
              }
            ]
          })
          setByPassPieChart({
            title: {
              text: ""
            },
            data: bypassPieChartData,
            series: [
              {
                type: "pie",
                angleKey: "value",
                labelKey: "label",
                calloutLabelKey: "label",
              }
            ]
          })


        }
      }).finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    setSelectedTime(time_interval[0])
  }, [])

  useEffect(() => {
    if (props.shiftId) {
      getVerificationCountStats();
      getVerificationCount();
      getVerifiedUserCount();
    }
  }, [props.shiftId, selectedTime, props.selectedCenters, props.selectedDistrict, props.selectedStates, props.selectedZones]);

  useEffect(() => {
    if (props.shiftId) {
      getVerificationByState()
    }
  }, [props.shiftId, selectedStateData]);

  return (
    <>
      <Card
        style={{
          backgroundColor: "#dcdcdc",
          borderRadius: "12px",
          border: "none",
        }}
        className='p-1 mb-2'
      >
        <div className="d-flex align-items-center  justify-content-end ">
          <FontAwesomeIcon icon={faRefresh} className='m-2 cursor-pointer' onClick={getVerificationCountStats} />
        </div>
        <Row>
          <Col>
            <Card
              style={{
                backgroundColor: "#f9f9f9",
                borderRadius: "12px",
                border: "none",
              }}
            >
              {
                loading ?
                  <CetLoader />
                  :
                  <Card.Body>
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <div>
                        <h6>Timely Verification Trends</h6>
                      </div>
                      <div>
                        <Select
                          options={time_interval}
                          className=""
                          value={selectedTime}
                          onChange={(e: any) => setSelectedTime(e)}
                        />
                      </div>
                    </div>
                    {lineChartOptions ? (
                      <AgCharts options={lineChartOptions} style={{ height: 340 }} />
                    ) : (
                      <p></p>
                    )}
                  </Card.Body>
              }
            </Card>
          </Col>
        </Row>
      </Card>

      <Card
        style={{
          backgroundColor: "#dcdcdc",
          borderRadius: "12px",
          border: "none",
        }}
        className='p-1 mb-2'
      >
        <div className="d-flex align-items-center  justify-content-end ">
          <FontAwesomeIcon icon={faRefresh} className='m-2 cursor-pointer' onClick={getVerifiedUserCount} />
        </div>

        {
          loading ?
            <>
              <CetLoader />
            </>
            :
            <>
              <Row className="mt-3">
                {/* <Col>
                  <Card
                    style={{
                      backgroundColor: "#f9f9f9",
                      borderRadius: "12px",
                      border: "none",
                    }}
                  >
                    <Card.Body>
                      <div className="d-flex justify-content-start mb-4">
                        <h6>Verification Statistics</h6>
                      </div>
                      {userCountBarChart ? (
                        <>
                          <AgCharts options={userCountBarChart} style={{ height: 340 }} />
                        </>
                      ) : (
                        <p></p>
                      )}
                    </Card.Body>
                  </Card>
                </Col> */}
                <Col>
                  <Card
                    style={{
                      backgroundColor: "#f9f9f9",
                      borderRadius: "12px",
                      border: "none",
                    }}
                  >
                    <Card.Body>
                      <div className="d-flex justify-content-start mb-4">
                        <h6>Overall Verification Statistics</h6>
                      </div>
                      {userCountPieChart ? (
                        <AgCharts options={userCountPieChart} style={{ height: 340 }} />
                      ) : (
                        <p></p>
                      )}
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col>
                  <Card
                    style={{
                      backgroundColor: "#f9f9f9",
                      borderRadius: "12px",
                      border: "none",
                    }}
                  >
                    <Card.Body>
                      <div className="d-flex justify-content-start mb-4">
                        <h6>Verfied User Statistics</h6>
                      </div>
                      {verifiedPieChart ? (
                        <AgCharts options={verifiedPieChart} style={{ height: 340 }} />
                      ) : (
                        <p></p>
                      )}
                    </Card.Body>
                  </Card>
                </Col>
                <Col>
                  <Card
                    style={{
                      backgroundColor: "#f9f9f9",
                      borderRadius: "12px",
                      border: "none",
                    }}
                  >
                    <Card.Body>
                      <div className="d-flex justify-content-start mb-4">
                        <h6>Exception Statistics in Percentage</h6>
                      </div>
                      {byPassPieChart ? (
                        <AgCharts options={byPassPieChart} style={{ height: 340 }} />
                      ) : (
                        <p></p>
                      )}
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </>
        }
      </Card>
      <div>
        <StateStats
          shiftId={props.shiftId}
          cities={props.cities}
        />
      </div>
    </>

  );
}
